import request from '@/utils/request'


// 查询系统消息列表
export function listSys(query) {
  return request({
    url: '/message/sys/list',
    method: 'get',
    params: query
  })
}

// 查询系统消息分页
export function pageSys(query) {
  return request({
    url: '/message/sys/page',
    method: 'get',
    params: query
  })
}

// 查询系统消息详细
export function getSys(data) {
  return request({
    url: '/message/sys/detail',
    method: 'get',
    params: data
  })
}

// 新增系统消息
export function addSys(data) {
  return request({
    url: '/message/sys/add',
    method: 'post',
    data: data
  })
}

// 修改系统消息
export function updateSys(data) {
  return request({
    url: '/message/sys/edit',
    method: 'post',
    data: data
  })
}

// 删除系统消息
export function delSys(data) {
  return request({
    url: '/message/sys/delete',
    method: 'post',
    data: data
  })
}
