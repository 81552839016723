<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" destroy-on-close>
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="消息封面" prop="cover" >
        <cos-upload type="image" :defaultList="form.coverArr" :count="1"
                    @input="getImg($event, 'cover')"></cos-upload>
      </a-form-model-item>
      <a-form-model-item label="消息标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入消息标题" />
      </a-form-model-item>
      <a-form-model-item label="消息副标题" prop="subtitle" >
        <a-input v-model="form.subtitle" placeholder="请输入消息副标题" />
      </a-form-model-item>
      <a-form-model-item label="消息内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
<!--      <a-form-model-item label="消息类型,0-平台消息,1-助手消息" prop="type" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSys, addSys, updateSys } from '@/api/message/sys'
import Editor from '@/components/Editor'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        cover: null,
        coverArr:[],
        coverCount:1,

        title: null,

        subtitle: null,

        content: null,

        type: 0,

        userId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cover: [
          { required: true, message: '消息封面不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '消息标题不能为空', trigger: 'blur' }
        ],
        subtitle: [
          { required: true, message: '消息副标题不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '消息类型,0-平台消息,1-助手消息不能为空', trigger: 'change' }
        ],
        userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getImg(info, fieldName) {
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        cover: null,
        title: null,
        subtitle: null,
        content: null,
        type: 0,
        userId: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.open = true
      this.reset()
      this.formType = 1
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.open = true
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSys({"id":id}).then(response => {
        this.form = response.data
        let that = this
        this.formTitle = '修改'
        if(this.form.cover){
          this.form.coverArr = []
          this.form.coverArr.push({
            uid: guid(8, 10),
            name: that.form.cover,
            status: 'done',
            halfUrl: that.form.cover,
            url: that.form.cover,
            path: that.form.cover
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSys(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSys(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
